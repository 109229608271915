@import 'variables';
@import 'header';
@import 'footer';
@import 'product';
@import 'home';
@import 'login';
@import 'button';
@import 'global';
@import 'register';
@import 'floatingAlert';
@import 'GoBack';
@import 'CartSummary';
@import 'CartLines';
@import 'inputNumber';
@import 'chat';
@import 'fontawesome-all.min.css';
@import '~video-react/styles/scss/video-react';
@import 'form';
@import 'advancedSearch';
@import 'breadcrumbs';
@import 'fonts';
@import 'background';
@import 'publicProfile';
@import 'simplifiedResult';

@font-face {
    font-family: 'unbounded';
    src: url('../fonts/unbounded-variable.ttf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    // font-family: 'unbounded';
}
body {
    margin: 0;
    font-family: $infoFont;
    font-weight: 300;
    color: $thunder;
    background: $bodyBackground !important;
    // margin-top: $headerHeight !important; /* Add a top margin to avoid content overlay */ passed to layout
    &.noscroll {
        @media (max-width: $mdMaxWidth) {
            position: fixed;
            overflow: hidden;
        }
    }
}

#wrapper-full a {
    color: inherit;
    text-decoration: unset;

    &:hover {
        color: inherit;
    }
}
#wrapper-full .colored-link {
    cursor: pointer;
    color: $linkColor;
    text-decoration: unset;
    &:hover {
        text-decoration: underline;
        color: $linkColor;
    }
}

#section-grid-view .sk-hits .card .card-body .price {
    font-size: 14px;
}
/*SAMPLE*/
#section-home,
#section-advanced-search,
#section-grid-view {
    .sk-hits {
        display: grid;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: 16px;
        grid-template-columns: repeat(5, 1fr);
        justify-self: center;
        width: 100%;

        .card {
            width: 100%;
            border-radius: 16px;
         @media (max-width: $smMaxWidth) {
            .card-image{
                background-position: left top !important;
                min-width: 140px;
                width: 140px;
            }
        }
            .card-body {
                padding: 8px 8px 8px;
                font-size: 12px;
                height: 100%;
                overflow-y:auto;
                ul {
                    margin-right: 16px;
                }
            }
            .price {
                font-size: 20px;
                @media (max-width: $smMaxWidth) {
                    font-size: 14px;
                }
            }
            .card-title a {
                word-wrap: break-word;
                word-break: break-word;
                font-size: 18px;
                color: $headerBackground !important;
            }
            .card-expansion {
                color: #697689 !important;
                margin-bottom: 8px;
            }
        }
        @media (max-width: $xxlMaxWidth) {
            grid-template-columns: repeat(4, 1fr);
            justify-self: center;
            .product-image-container,
            .product-image {
                height: 180px;
            }
        }
        @media (max-width: $xlMaxWidth) {
            grid-template-columns: repeat(3, 1fr);
            justify-self: center;
            .product-image-container,
            .product-image {
                height: 180px;
            }
        }
        @media (max-width: $lgMaxWidth) {
            grid-template-columns: repeat(2, 1fr);
            justify-self: center;
            .product-image-container,
            .product-image {
                height: 180px;
            }
        }
        @media (max-width: $mdMaxWidth) {
            grid-template-columns: repeat(2, 1fr);
            .card {
                margin: 0px 32px;
                .product-image-container,
                .product-image {
                    height: 200px;
                }
                justify-self: center;
            }
        }
        @media (max-width: $smMaxWidth) {
            grid-template-columns: repeat(1, 1fr);
            .card {
                margin: 0px 32px;
                .product-image-container,
                .product-image {
                    height: 200px;
                }
                justify-self: center;
            }
        }
        @media (max-width: $smallmobilemaxwidth) {
            grid-template-columns: repeat(1, 1fr);
            .card {
                margin: 0px 32px;
                .product-image-container,
                .product-image {
                    height: 200px;
                }
                justify-self: center;
            }
        }
    }
}

#section-grid-view {
    .properties{
            margin: 0 16px 0 0;
    }
    .sk-hits {
        grid-template-columns: repeat(4, 1fr);
        @media (max-width: $xxlMaxWidth) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: $xlMaxWidth) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: $lgMaxWidth) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: $mdMaxWidth) {
            grid-template-columns: repeat(1, 1fr);
        }
        @media (max-width: $smMaxWidth) {
            grid-template-columns: repeat(1, 1fr);
        }
        @media (max-width: $smallmobilemaxwidth) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

/*SPINNER*/
.sk-cube-grid {
    position: relative;
    top: 40%;
}
.sk-wandering-cubes {
    $animationDuration: 1.8s;

    width: $spinkit-size;
    height: $spinkit-size;
    position: relative;
    margin: auto;

    .sk-cube {
        background-color: $spinkit-spinner-color;
        width: ($spinkit-size / 4);
        height: ($spinkit-size / 4);
        position: absolute;
        top: 50%;
        left: 0;
        animation: sk-wandering-cubes $animationDuration ease-in-out #{-$animationDuration} infinite both;
    }

    .sk-cube-2 {
        animation-delay: (-$animationDuration / 2);
    }
}

@keyframes sk-wandering-cubes {
    $cubeDistance: ($spinkit-size / 2);
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: translateX($cubeDistance) rotate(-90deg) scale(0.5);
    }
    50% {
        /* Hack to make FF rotate in the right direction */
        transform: translateX($cubeDistance) translateY($cubeDistance) rotate(-179deg);
    }
    50.1% {
        transform: translateX($cubeDistance) translateY($cubeDistance) rotate(-180deg);
    }
    75% {
        transform: translateX(0) translateY($cubeDistance) rotate(-270deg) scale(0.5);
    }
    100% {
        transform: rotate(-360deg);
    }
}

/*SPINNER*/

.results-pagination {
    margin-top: 16px;
    .sk-pagination-navigation {
        .sk-toggle {
            text-align: center;

            .sk-toggle-option {
                background: $gray;
                border: 1px solid $gray;
                color: #fff;

                cursor: pointer;
                display: inline-block;
                margin-bottom: 10px;
                padding: 2px 10px;

                &.is-disabled {
                    background: $mercury;
                    color: $iron;
                    display: none;
                }

                &:not(.is-disabled) {
                    &.is-active {
                        background: $thunder;
                        border-color: $thunder;
                    }

                    &:hover {
                        background: $thunder;
                        border-color: $thunder;
                    }
                }

                &:not(:first-child) {
                    border-left: 1px solid $white;
                }

                &:not(:last-child) {
                    border-right: none;
                }

                &[data-key^='ellipsis'] {
                    display: inline-block;
                }

                &[data-key='previous'],
                &[data-key='next'] {
                }
            }
        }
    }

    .sk-toggle-option.sk-toggle__item {
        background-color: transparent !important;
        border: none !important;
        color: #69696b !important;
    }

    .results-pagination {
        margin-top: 1rem;
    }

    .sk-toggle-option.sk-toggle__item.is-active {
        font-weight: bold !important;
    }

    .sk-toggle-option.sk-toggle__item[data-key='previous'] > div.sk-toggle-option__text > div {
        display: none !important;
    }

    .sk-toggle-option.sk-toggle__item[data-key='previous'] > div.sk-toggle-option__text:before {
        font-family: 'Font Awesome 5 Pro';
        content: '\f177';
        //content: '\f053';
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        font-weight: 900;
    }

    .sk-toggle-option.sk-toggle__item[data-key='next'] > div.sk-toggle-option__text > div {
        display: none !important;
    }

    .sk-toggle-option.sk-toggle__item[data-key='next'] > div.sk-toggle-option__text:before {
        font-family: 'Font Awesome 5 Pro';
        content: '\f178';
        //content: '\f054';
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        font-weight: 900;
    }
}

.table {
    border: none;
    border-radius: 4px;
}

.table .pagination-bottom .-pagination {
    box-shadow: none;
    @media (max-width: $mdMaxWidth) {
        .-center {
            display: none;
        }
    }
}
.table .rt-thead.-header {
    box-shadow: none;
    font-weight: unset;
    letter-spacing: 2px;
    background-color: #f4f4f4;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    box-shadow: none;
}

.table .rt-thead.-header .rt-tr {
    background: transparent;
    // color: white;
    border-left: none;
}

.table .rt-tr {
    background-color: #efefef;
    color: $blackBackground;
    border-left: none;
    margin: 5px;
    padding: 2px 0;
    text-align: center;
    display: flex;
    align-items: center;
}

.table .rt-tr:hover {
    background-color: $aluminum;
}

.table .rt-thead .rt-th.-cursor-pointer {
    outline: none;
}

.table .rt-thead .rt-th.-sort-asc {
    box-shadow: inset 0 3px 0 0 rgba(255, 255, 255, 0.8) !important;
}

.table .rt-thead .rt-th.-sort-desc {
    box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, 0.8) !important;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
    white-space: unset;
}

#order-finished-container {
    position: fixed;
    top: 20%;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 950px;
    background: white;
    z-index: $layer50;
    .logo-container {
        background: 'white';
        #logo-img {
            top: 20%;
            position: relative;
        }
    }
    @media (max-width: $mdMaxWidth) {
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow-x: none;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}
.order-finished {
    label,
    .forgot-password a,
    .forgot-password span {
        color: $white;
    }

    header {
        padding: 20px 25px;
        .bt-close {
            display: inline-block;
            cursor: pointer;

            &:hover {
                color: $onyx;
            }

            i {
                font-size: 40px;
            }
        }
    }
    h2 {
        padding-bottom: 40px;
    }
    footer {
        padding: 20px;
    }
}
.card-title {
    word-wrap: break-word;
    word-break: break-word;
}
